import {request} from "@/network/request";

// 产品分类
export function getProductCate() {
    return request({
        url: 'product_categories',
        needPassword: true, // 设置需要密码
    });
}

//产品列表
export function getProductList(id,page) {
    return request({
        url: 'products/'+id,
        params:{
            page
        },
        needPassword: true,
    })
}

//产品详情
export function getProductDetails(id) {
    return request({
        url: 'product/'+ id,
        needPassword: true,
    })
}

// 购物车
export function getCart(id) {
    return request({
        url: 'product_components',
        params: {
            id: id
        },
        needPassword: false
    });
}

//搜索
export function getSearch(keyword) {
    return request({
        url: 'search_keyword/'+ keyword,
        needPassword: true
    });
}

//免密码页面
export function getMobileDetails(code) {
    return request({
        url: 'replacement/'+ code,
        needPassword: false,
    })
}


//----------------post请求-----------------//
//上傳图片
export function  UploadImg(params) {
    return request({
        url: 'upload/image',
        method: 'post',
        data: params,
        needPassword: false
    })
}
//提交
export function  subForm(params) {
    return request({
        url: 'order',
        method: 'post',
        data: params,
        needPassword: false
    })
}

//购物车校验库存
export function  confirmStock(params) {
    return request({
        url: 'order/check_repertory',
        method: 'post',
        data: params,
        needPassword: false
    })
}

//国家
export function getCountry(keyword) {
    return request({
        url: 'countries',
        params: { keyword },
        needPassword: true
    });
}


