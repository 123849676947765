import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/productDetails/:id',
    name: 'productDetails',
    component: () => import('@/views/productDetails.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/Cart.vue'),
    props: route => {
      const id = route.query.id;
      if (id) {
        return { id: id.split(',') };
      } else {
        return { id: [] };
      }
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/Order.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/Result.vue')
  },
  {
    path: '/search',
    name: 'searchData',
    component: () => import('@/views/searchData.vue')
  },
   //--------------------------扫码页面-------------------
  {
    path: '/replacement/:code',
    name: 'replacement',
    component: () => import('@/views/replacement.vue'),
    meta: { showHeader: false }
  },
  {
    path: '/mobileCart',
    name: 'mobileCart',
    component: () => import('@/views/mobileCart.vue'),
    props: route => {
      const id = route.query.id;
      if (id) {
        return { id: id.split(',') };
      } else {
        return { id: [] };
      }
    },
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: '/mobileOrder',
    name: 'mobileOrder',
    component: () => import('@/views/mobileOrder.vue'),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: '/mobileResult',
    name: 'mobileResult',
    component: () => import('@/views/mobileResult.vue'),
    meta: {
      showHeader: false,
      showFooter: false,
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})


//路由点击跳转后页面回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
