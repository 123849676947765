<template>

  <!--密码弹窗-->
  <div class="pop-up" v-if="isShow">
    <div class="pop-container">
      <h3>Password</h3>
      <input class="password" type="password" placeholder="Password" v-model="password"  @keydown.enter="savePassword" ref="passwordInput">
      <p class="tips">{{tips}}</p>
      <div class="confirm-btn"  @click="savePassword">
        Confirm
      </div>
    </div>
  </div>

  <!-- 加载中 -->
  <div class="main" v-if="isLoadingShow">
    <Loading/>
  </div>

<div class="container">
<div class="index">
  <h1>Replacement Parts take approximately 7-10  business days to receive.</h1>
  <div class="middle">
    <div class="index-left">
      <div class="in-product">
         Category
      </div>
      <div class="product-list">
        <ul>
          <li v-for="item in productCate" :key="item.id" :class="{ 'active': item.id === id }" @click="handleClick(item.id)">
            {{item.title}}
          </li>
        </ul>
      </div>
    </div>
    <div class="index-right">
     <div class="current-name">
       <h3>{{ currentTitle }}</h3>
     </div>
      <div class="pro-item">
        <ul>
            <li v-for="item in productList" :key="item.id" @click="details(item.id)">
                <div class="add-year">
                  <span>{{item.year}}</span>
                </div>
                <img :src="item.cover">
                <div class="pro-item-txt">
                  <h3>{{item.title}}</h3>
                  <p><span>No:{{item.no}}</span></p>
                </div>
            </li>
        </ul>
      </div>

      <Pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          :show-pagination="showPagination"
          @goto-page="onPageChange"
      />

    </div>
  </div>
</div>
</div>
</template>

<script>
import { getProductCate, getProductList } from "@/network/main";
import Pagination from "@/components/Pagination";
import Loading from "@/components/loading";

export default {
  name: "Home",
  components: {
    Pagination,
    Loading
  },
  data() {
    return {
      isShow: true,
      isLoadingShow:true,
      tips: "",
      password: "",
      productCate: [],
      id: "",
      currentTitle: "",
      productList: [],
      currentPage: 1, // 当前页码
      totalPages: 1, // 总页数
      showPagination: false, // 是否显示分页
    };
  },
  created() {
    // 判断是否有缓存
    const password = this.getCookie("password");
    if (password !== null && password !== undefined) {
      this.isShow = false;
      // 产品分类   --如果有密码，就去调用接口
      this.getProductCate();
    } else {
      this.isShow = true;
      this.isLoadingShow = false;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    // 获取指定名称的cookie值
    getCookie(name) {
      const cookies = document.cookie.split("; ");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
          return cookie[1];
        }
      }
      return null;
    },
    // 设置cookie
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },
   //键盘enter
    handleKeyDown(event) {
      if (event.keyCode === 13 && document.activeElement === this.$refs.passwordInput) {
        this.savePassword();
      }
    },
    // 保存密码为cookie
    savePassword() {
      if (!this.password) {
        // 密码为空，进行相应的处理
        this.tips='Password is required'
        return;
      }
      this.setCookie("password", this.password, 1);
      console.log("Password:", this.password); // 打印密码
      this.getProductCate();
    },

    // 获取产品分类
    getProductCate() {
      getProductCate().then((res) => {
        if(res.data.code === 401){
          this.tips = res.data.message;
          console.log(res.data.code)
        } else if (res.data.code === 200) {
          this.isShow = false;
          this.isLoadingShow = false;
          this.productCate = res.data.data;
          if (this.productCate.length > 0) {
            this.id = this.productCate[0].id; // 拿第一条数据的id和标题
            this.currentTitle = this.productCate[0].title;
            console.log(this.id);
            // 获取产品列表
            this.getProductList(this.id);
          }
        }
      });
    },
    // 获取产品列表
    getProductList(id,page) {
      getProductList(id,page).then((res) => {
        this.productList = res.data.data.data;
        this.totalPages = res.data.data.last_page;
        this.showPagination = this.totalPages > 1; // 是否显示分页
        this.isLoadingShow = false;
      });
    },
    // 点击分类项
    handleClick(id) {
      this.id = id;
      this.currentTitle = this.productCate.find((item) => item.id === id).title;
      this.getProductList(this.id);
      this.isLoadingShow = true;
    },
    //产品点击
    details(id){
      this.$router.push('/productDetails/' + id)
    },
    //点击分页按钮
    onPageChange(page) {
      this.currentPage = page;
      this.getProductList(this.id, this.currentPage);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style scoped>
.index h1{
  padding: 30px 0;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  color: #666;
}
.middle{
 display: flex;
 justify-content: space-between;
}
.index-left{
  width: 288px;
  margin-bottom: 30px;
}
.in-product{
  color: var(--color-high-text);
  background: var(--background);
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom:20px;
}
.product-list{
   border-radius: 4px;
  border: 1px solid #DEDEDE;
}
.product-list ul{
  padding: 10px 20px;
}
.product-list ul li{
  list-style: none;
  padding:15px 20px;
  border-radius: 4px;
  margin:10px 0;
  font-weight: bold;
  cursor: pointer;
}
.product-list ul li.active{
  color: var(--color-high-text);
  background: var(--background);
}

.index-right{
  width: calc(100% - 340px);
}
.current-name{
  height: 60px;
  line-height: 60px;
  background: #EEEEEE;
  padding: 0 20px;
  margin-bottom: 20px;
}
.pro-item ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pro-item ul li{
  width:31%;
  list-style: none;
  margin-bottom: 40px;
  box-shadow: 1px 6px 10px #ededed;
  cursor: pointer;
  position: relative;
}

.add-year{
  position: absolute;
  right: 20px;
  top: 20px;
}

.add-year span{
  background: #FF3A3A;
  color: #fff;
  padding: 5px 10px;
  font-size: 17px;
  border-radius: 4px;
}

.pro-item ul li img{
  max-height: 300px;
  margin: auto;
  display: block;
}

.pro-item ul:after {
  content: "";
  width:31%;
}
.pro-item-txt{
  padding:10px 10px 20px 10px;
}
.pro-item-txt h3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.pro-item-txt p{
 padding-top: 10px;
}
.pro-item-txt p span{
  background: #b7b7b7;
  color: #fff;
  padding: 5px;
  display: inline-block;
  border-radius: 4px;
}

@media only screen and (min-width:0px) and (max-width:992px){
  .pro-item ul li{
    width:48%;
  }
  .pro-item ul:after {
    width:48%;
  }
}

@media only screen and (min-width:0px) and (max-width:767px){
  .middle{
    flex-wrap: wrap;
  }
  .index-left{
    width: 100%;
    margin-bottom: 20px;
  }
  .index-right{
    width: 100%;
  }
  .in-product{
    display:none;
  }
  .index h1{
     font-size: 24px;
      padding: 15px 0;
  }
  .product-list ul{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .product-list ul li{
    margin: 0;
    padding: 6px 12px;
  }
}

</style>